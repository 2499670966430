/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React,{useEffect} from 'react';
import '../../individual/dashboard/DashBoard.scss';
import RedeemWine from './redeemWine/RedeemWine';
import RedeemCoffee from './redeemCoffee/redeemCoffee';
import SelectProduct from './RedeemGift/SelectProduct';
import NewRedeem from './newredeem/newRedeem';
import ReactGA from 'react-ga';

const RedeemAnonymous = () => {

  const anonymous = JSON.parse(sessionStorage.getItem('redeemInfo'));

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  if (anonymous.type === 'Wine') {
    return <RedeemWine />;
  } 
  else if (anonymous.type === 'Beer' || anonymous.type === 'Chocolate' || anonymous.type === 'Hamper' || anonymous.type === 'Spirits') {
    return <SelectProduct />;
  } else if (anonymous.type == 'Coffee') {
    return <RedeemCoffee />
  }

};

export default RedeemAnonymous;
