/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';
import axios from 'axios';

const GetACCOuntDetails = () => {
  return httpService.get('Users/current');
};

const getCurrentUserDetails = () => {
  return httpService.get('UserSessions/current');
};

const updateUserDetails = data => {
  // return httpService.put('Users/current', data);
  return axios({
    url: `${process.env.REACT_APP_BASE_URL}/Users/current`,
    method: 'PUT',
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updateUserPassword = data => {
  return httpService.patch('Users/current', data);
};

const redirectToDoxAI = () => {
  return httpService.post('Apps/DoxAI');
};


export default {
  GetACCOuntDetails,
  getCurrentUserDetails,
  updateUserDetails,
  updateUserPassword,
  redirectToDoxAI
};
